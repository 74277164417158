
import React, { useState, useEffect }  from 'react';
import logo from './logo.svg';
import './App.css';

import Axios from 'axios';


function App() {
  
  const [List, setList] = useState ([]);


  Axios.defaults.withCredentials = true; 


  useEffect( () => {
    
    // alert (`${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_PORT}/api/employees`)
    
    Axios.get(`/api/employees`)
    .then ((res) =>  {
          setList(res.data)
          // console.log (`res.data:  ${res.data}`) 
      })
    .catch( (err) => {
          setList([])  
          console.log (err);
      });

}, []) ;







  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
           <strong> THE REACT PROXY VERSION port 8100</strong>
        </p>
            {
                
                
                List.map ( lst => (  <div key={lst.EmployeeID}>
                                            <h1> {lst.EmployeeID}   </h1>
                                            <h2> {lst.Lastname} </h2>
                                            <h3> {lst.Firstname}  </h3>
                                          </div>
                                        )
                          )              
            }

        
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
